import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { format, distanceInWords, differenceInDays } from "date-fns";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../../lib/helpers";
import BlogPostPreviewList from "../../components/blog-post-preview-list";
import Container from "../../components/container";
import GraphQLErrorList from "../../components/graphql-error-list";
import SEO from "../../components/seo";
import Layout from "../../containers/layout";
import styled from "styled-components";
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, PanelLabel } from "../../containers";
import Hero from "../../components/hero";
import PortableText from "../../components/portableText";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import NumberFormat from "react-number-format";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { DiscussionEmbed } from "disqus-react";
import { MdHelpOutline, MdHelp, MdCheck, MdWarning, MdInfo, MdInfoOutline } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'
import GoogleAdWrapper from '../../components/GoogleAd'
import { GumroadAd } from '../../components/GumroadAd'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query FireCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors {
            author {
              name
              id
            }
          }
          mainImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const H1 = styled.h1`
  @media (max-width: 500px) {
    font-size: 1.4rem;
  }
`;

const H2 = styled.h2`
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

const CalculatorGrid = styled.div`
  margin: 0 auto;
  padding: 24px;
  max-width: 1400px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  background-color: ${(props) => props.theme.theme.bg.primary};
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: grid;
  /* grid-template-columns: 1fr; */
  grid-template-columns: 1fr;
  grid-gap: 0px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 0;
    border: none;
    border-radius: 0px;
    margin: 0 0 0 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`;

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0px 0 0 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }

`;

const NeutralTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0px 0 0 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover {
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${(props) => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${(props) => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.colors.green};
  }
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin: 0 0 0 4px;
  }
`;

const GroupLabel = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`;

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`;

const TooltipPanel = styled.div`
  background-color: ${(props) => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`;

const InputSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 12px 12px 12px;
  border-left: 2px solid;
  /* border-radius: 8px; */
  margin: 0 0 24px 0;
`;

const InputSectionGreen = styled(InputSection)`
  border-color: ${(props) => props.theme.theme.colors.green};

  .GroupLabel {
    color: ${(props) => props.theme.theme.colors.green};
  }
`;

const InputSectionBlue = styled(InputSection)`
  border-color: ${(props) => props.theme.theme.colors.blue};

  .GroupLabel {
    color: ${(props) => props.theme.theme.colors.blue};
  }
`;

const InputGroup = styled.div`
  /* display: grid; */
  display: flex;
  flex-wrap: wrap;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
  /* grid-gap: 24px; */

  @media (max-width: 500px) {
    padding: 24px 12px 0 12px;
  }
`;

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${(props) => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`;

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 4px;
  align-items: center;
  margin: 0 0 0 0;
`;

const FlexInput = styled.div`
  margin: 0px 12px 12px 0;
`;

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 12px 12px 0;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const AllocationGroupRed = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentRed};
  border: 1px solid ${(props) => props.theme.theme.colors.red};
  color: ${(props) => props.theme.theme.colors.red};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.red};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.red};
  }
`;

const AllocationGroupGreen = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  color: ${(props) => props.theme.theme.colors.green};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.green};
  }
`;

const AllocationGroupPurple = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentPurple};
  border: 1px solid ${(props) => props.theme.theme.colors.purple};
  color: ${(props) => props.theme.theme.colors.purple};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.purple};
  }
`;

const AllocationGroupOrange = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentOrange};
  border: 1px solid ${(props) => props.theme.theme.colors.orange};
  color: ${(props) => props.theme.theme.colors.orange};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.orange};
  }
`;

const AllocationGroupBlue = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  border: 1px solid ${(props) => props.theme.theme.colors.blue};
  color: ${(props) => props.theme.theme.colors.blue};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.blue};
  }
`;

const AllocationGroupBlack = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.colors.black};
  color: ${(props) => props.theme.theme.colors.black};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.colors.black};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.colors.black};
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }
`;

const AllocationGroupRow = styled.div`
  display: grid;
  grid-template-columns: 90px 100px 60px;
  grid-gap: 18px;
`;

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const AllocationMessage = styled.div`
  display: grid;
  grid-template-columns: 35px 1fr;
  width: 225px;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0 12px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const NotePanel = styled(AllocationGroup)`
  color: ${(props) => props.theme.theme.text.tertiary};
  padding: 12px;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 1fr;

  svg {
    margin: 0 4px 0 0;
  }
`;

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const CustomTooltip = (props) => {
  const { active } = props;

  if (active) {
    const { payload, label } = props;
    // console.log(payload);
    // console.log(label);

    return (
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Age: </strong>
          {payload[0].payload.age}
        </CardText400>
        <CardText400>
          <strong>Net Worth: </strong>
          <NumberFormat
            displayType={"text"}
            value={payload[0].payload.actualNetWorth.toFixed(0)}
            thousandSeparator
            prefix="$"
          />
        </CardText400>
        {/* <CardText400>
          <strong>Coast FIRE number: </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.theoreticalNetWorth.toFixed(0)}
              thousandSeparator
              prefix="$"
          />
        </CardText400> */}
        <CardText400>
          <strong>FIRE number: </strong>
          <NumberFormat
            displayType={"text"}
            value={payload[0].payload.fireNumber.toFixed(0)}
            thousandSeparator
            prefix="$"
          />
        </CardText400>
      </TooltipPanel>
    );
  }
  return null;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FireCalc = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const [currentAge, setCurrentAge] = useState(30);
  const [currentTakeHomePay, setCurrentTakeHomePay] = useState(80000);
  const [currentSalary, setCurrentSalary] = useState(100000);
  const [currentAnnualSpending, setCurrentAnnualSpending] = useState(40000);
  const [currentNetWorth, setCurrentNetWorth] = useState(100000);
  const [graphEndAge, setGraphEndAge] = useState(67);
  const [annualSpend, setAnnualSpend] = useState(40000);
  const [investmentRate, setInvestmentRate] = useState(7);
  const [stocksRate, setStocksRate] = useState(8);
  const [stocksAllocation, setStocksAllocation] = useState(90);
  const [bondsRate, setBondsRate] = useState(5);
  const [bondsAllocation, setBondsAllocation] = useState(0);
  const [cashRate, setCashRate] = useState(0.5);
  const [cashAllocation, setCashAllocation] = useState(10);
  const [miscRate, setMiscRate] = useState(5);
  const [miscAllocation, setMiscAllocation] = useState(0);
  const [sideIncomeMonthly, setSideIncomeMonthly] = useState(0);
  const [sideIncomeAgeRange, setSideIncomeAgeRange] = useState([50, 60]);
  const [companyMatch, setCompanyMatch] = useState(0);
  const [incomeGrowthRate, setIncomeGrowthRate] = useState(3);
  const [retirementAge, setRetirementAge] = useState(45);

  const [inflationRate, setInflationRate] = useState(3);
  const [SWR, setSWR] = useState(4);
  const [payment, setPayment] = useState(500);
  const [annualCompoundingPeriods, setAnnualCompoundingPeriods] = useState(1);
  const [annualPaymentPeriods, setAnnualPaymentPeriods] = useState(1);

  // console.log(`stocksAllocation: ${stocksAllocation}`)
  // console.log(`stocksRate: ${stocksRate}`)
  // console.log(`bondsAllocation: ${bondsAllocation}`)
  // console.log(`bondsRate: ${bondsRate}`)
  // console.log(`cashAllocation: ${cashAllocation}`)
  // console.log(`cashRate: ${cashRate}`)
  // console.log(`miscAllocation: ${miscAllocation}`)
  // console.log(`miscRate: ${miscRate}`)

  const totalAllocation =
    parseFloat(stocksAllocation) +
    parseFloat(bondsAllocation) +
    parseFloat(cashAllocation) +
    parseFloat(miscAllocation);

  const weightedAverageRate =
    (stocksRate * stocksAllocation +
      bondsRate * bondsAllocation +
      cashRate * cashAllocation +
      miscRate * miscAllocation) /
    totalAllocation;

  const rate =
    Math.pow(
      1 + (weightedAverageRate - inflationRate) / 100 / annualCompoundingPeriods,
      annualCompoundingPeriods / annualPaymentPeriods
    ) - 1;

    // console.log(`rate: ${rate}`)

  const fireNumber = annualSpend / (0.01 * SWR);

  let fireAge = 0;
    console.log(fireAge)

  const calcNetWorthData = () => {
    let netWorthData = [];

    for (let i = currentAge; i <= graphEndAge; i++) {
      // co.log('test')

      const time = i - currentAge;
      const localNper = annualPaymentPeriods * time;

      if (i == currentAge) {
        netWorthData.push({
          age: i,
          year: i - currentAge,
          actualNetWorth: parseFloat(currentNetWorth),
          annualIncome: "N/A",
          annualSavings: "N/A",
          investmentGains: "N/A",
          // theoreticalNetWorth: currentNetWorth * ( 1 + rate ) + (currentTakeHomePay - setCurrentAnnualSpending) + 19500 * (companyMatch / 100),
          fireNumber: fireNumber,
          continuousGrowthNetWorth: currentNetWorth,
        });
      } else {
        if (i == currentAge + 1) {
          netWorthData.push({
            age: i,
            year: i - currentAge,
            actualNetWorth:
              currentNetWorth * (1 + rate) +
              (currentTakeHomePay - currentAnnualSpending) +
              currentSalary * (companyMatch / 100),
            annualIncome: parseFloat(currentTakeHomePay),
            annualSavings: currentTakeHomePay - currentAnnualSpending,
            investmentGains: currentNetWorth * rate,
            // theoreticalNetWorth: currentNetWorth * ( 1 + rate ) + (currentTakeHomePay - setCurrentAnnualSpending) + 19500 * (companyMatch / 100),
            fireNumber: fireNumber,
            continuousGrowthNetWorth:
              currentNetWorth * (1 + rate) +
              (currentTakeHomePay - currentAnnualSpending) +
              currentSalary * (companyMatch / 100),
          });
        } else {
          const prev = i - 1 - currentAge;

          const instanceTakeHomePay =
            currentTakeHomePay *
            Math.pow(1 + 0.01 * (incomeGrowthRate - inflationRate), i - currentAge - 1);
          // console.log(instanceTakeHomePay);

          const instancePreTaxPay =
            currentSalary *
            Math.pow(1 + 0.01 * (incomeGrowthRate), i - currentAge - 1);

          // const actualNetWorthDataPoint = netWorthData[prev].actualNetWorth * ( 1 + rate ) + ( instanceTakeHomePay - currentAnnualSpending ) + 19500 * (companyMatch / 100)

          const continuousGrowthNetWorth =
            netWorthData[prev].actualNetWorth * (1 + rate) +
            (instanceTakeHomePay - currentAnnualSpending) +
            instancePreTaxPay * (companyMatch / 100);

          let actualNetWorthDataPoint = continuousGrowthNetWorth;

          const postRetirementNetWorth = 
            netWorthData[prev].actualNetWorth * (1 + rate) +
              - annualSpend

          // if(netWorthData[prev].actualNetWorth >= fireNumber){
          //   actualNetWorthDataPoint = 0
          // } else {
          //   actualNetWorthDataPoint = continuousGrowthNetWorth
          // }

          // netWorthData.push({
          //   age: i,
          //   year: i - currentAge,
          //   actualNetWorth: actualNetWorthDataPoint,
          //   annualIncome: instanceTakeHomePay,
          //   annualSavings: instanceTakeHomePay - currentAnnualSpending,
          //   investmentGains: netWorthData[prev].actualNetWorth * rate,
          //   // theoreticalNetWorth: coastNumber * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)),
          //   fireNumber: fireNumber,
          //   continuousGrowthNetWorth: continuousGrowthNetWorth,
          // });


          if (!fireAge && continuousGrowthNetWorth >= fireNumber) {
            fireAge = i - 1;
          }

          if(i > retirementAge){
            netWorthData.push({
              age: i,
              year: i - currentAge,
              actualNetWorth: postRetirementNetWorth,
              annualIncome: 0,
              annualSavings: 0,
              investmentGains: 0,
              // theoreticalNetWorth: coastNumber * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)),
              fireNumber: fireNumber,
              continuousGrowthNetWorth: postRetirementNetWorth,
            });
          }

          else {

            netWorthData.push({
              age: i,
              year: i - currentAge,
              actualNetWorth: actualNetWorthDataPoint,
              annualIncome: instanceTakeHomePay,
              annualSavings: instanceTakeHomePay - currentAnnualSpending,
              investmentGains: netWorthData[prev].actualNetWorth * rate,
              // theoreticalNetWorth: coastNumber * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)),
              fireNumber: fireNumber,
              continuousGrowthNetWorth: continuousGrowthNetWorth,
            });

          }

          // if( actualNetWorthDataPoint < fireNumber){
          //   graphEndAge = i + 5
          //   console.log(graphEndAge)
          // }
        }
      }

      // netWorthData.push({
      //   age: i,
      //   actualNetWorth: currentNetWorth * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate ) ,
      //   theoreticalNetWorth: coastNumber * Math.pow( (1 + (.01*(rate - inflationRate)) ), (i - currentAge)),
      //   fireNumber: fireNumber
      // })
    }
    return netWorthData;
  };

  const netWorthData = calcNetWorthData();

  console.log(netWorthData);

  return (
    <Layout>
      <SEO
        title={"FIRE Calculator - Financial Independence Retire Early"}
        description={
          "Visualize and calculate how much money you need to reach Financial Independence"
        }
        keywords={site.keywords}
      />
      <CustomReactTooltip />
      <ContainerMain>
        <H1>Financial Independence / Retire Early (FIRE) Calculator</H1>
        <H2>Visualize and calculate how much you need to reach Financial Independence</H2>
      </ContainerMain>

      <CalculatorGrid>
        <form>
          <InputGroup>
            <FlexInput style={{ width: "100px" }}>
              <ThemedTextarea
                id="outlined-name"
                label="Current Age"
                value={currentAge}
                onChange={(event) => setCurrentAge(event.target.value)}
                variant="outlined"
                size="small"
                fullWidth
              />
            </FlexInput>

            <FlexInput style={{ width: "150px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Retirement Age"
                  value={retirementAge}
                  onChange={(event) => setRetirementAge(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`Adjust this value to change the age where you would stop earning an income from your day job.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput style={{ width: "160px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Post-tax Salary"
                  value={currentTakeHomePay}
                  onChange={(event) => setCurrentTakeHomePay(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your current annual take-home pay after all taxes.
                    This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
                />
              </InputItem>
            </FlexInput>
            <FlexInput style={{ width: "160px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Pre-tax Salary"
                  value={currentSalary}
                  onChange={(event) => setCurrentSalary(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your current annual take-home pay after all taxes.
                    This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
                />
              </InputItem>
            </FlexInput>
            <FlexInput style={{ width: "170px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Annual Spending"
                  value={currentAnnualSpending}
                  onChange={(event) => setCurrentAnnualSpending(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp size="1.2rem" data-tip={`This is your current annual spending.`} />
              </InputItem>
            </FlexInput>

            <FlexInput style={{ width: "240px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Retirement Annual Spending"
                  value={annualSpend}
                  onChange={(event) => setAnnualSpend(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your estimated annual spending in retirement.
                    Consider some spending areas like health insurance will increase while others like your mortgage may decrease once you pay off your home.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput style={{ width: "170px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="Current Net Worth"
                  value={currentNetWorth}
                  onChange={(event) => setCurrentNetWorth(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your current total net worth. This includes assets like cash, bonds, stock market holdings, and retirement accounts,
                    This would not include equity in real estate unless you plan to sell your real estate in retirement.`}
                />
              </InputItem>
            </FlexInput>

            <FlexInput style={{ width: "100px" }}>
              <InputItem>
                <ThemedTextarea
                  id="outlined-name"
                  label="End Age"
                  value={graphEndAge}
                  onChange={(event) => setGraphEndAge(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
                <MdHelp
                  size="1.2rem"
                  data-tip={`Adjust this value to change the end age on the graph to zoom in or out.`}
                />
              </InputItem>
            </FlexInput>

            <AllocationGroupRed>
              <Label>
                Stocks Allocation
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the percentage of your net worth that is in the stock market.
                        Adjust the slider or the value in the 'return' box to set your average stock market return NOT adjusted for inflation.
                        Historically this return has been 8 to 10%.`}
                />
              </Label>
              <AllocationGroupRow>
                <ThemedTextarea
                  id="outlined-name"
                  label="Allocation"
                  value={stocksAllocation}
                  onChange={(event) => setStocksAllocation(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
                <SliderBlack
                  value={stocksRate}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={0}
                  max={14}
                  marks={[
                    {
                      value: 0,
                      label: "0%",
                    },
                    {
                      value: 8,
                      label: "8%",
                    },
                    {
                      value: 14,
                      label: "14%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setStocksRate(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Return"
                  value={stocksRate}
                  onChange={(event) => setStocksRate(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRow>
            </AllocationGroupRed>

            <AllocationGroupPurple>
              <Label>
                Bonds Allocation
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the percentage of your net worth that is held in bonds.
                        Adjust the slider or the value in the 'return' box to set your average bond return NOT adjusted for inflation.
                        Historically this return has been 5 to 6%.`}
                />
              </Label>
              <AllocationGroupRow>
                <ThemedTextarea
                  id="outlined-name"
                  label="Allocation"
                  value={bondsAllocation}
                  onChange={(event) => setBondsAllocation(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
                <SliderBlack
                  value={bondsRate}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={0}
                  max={10}
                  marks={[
                    {
                      value: 0,
                      label: "0%",
                    },
                    {
                      value: 5,
                      label: "5%",
                    },
                    {
                      value: 10,
                      label: "10%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setBondsRate(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Return"
                  value={bondsRate}
                  onChange={(event) => setBondsRate(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRow>
            </AllocationGroupPurple>

            <AllocationGroupOrange>
              <Label>
                Cash Allocation
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the percentage of your net worth that you hold in cash.
                      Adjust the slider or the value in the 'return' box to set your interest rate of cash NOT adjusted for inflation.
                      Currently (in December 2020) this value is about 0.5%.`}
                />
              </Label>
              <AllocationGroupRow>
                <ThemedTextarea
                  id="outlined-name"
                  label="Allocation"
                  value={cashAllocation}
                  onChange={(event) => setCashAllocation(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
                <SliderBlack
                  value={cashRate}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={0}
                  max={5}
                  marks={[
                    {
                      value: 0,
                      label: "0%",
                    },
                    {
                      value: 1,
                      label: "1%",
                    },
                    {
                      value: 5,
                      label: "5%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setCashRate(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Return"
                  value={cashRate}
                  onChange={(event) => setCashRate(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRow>
            </AllocationGroupOrange>

            <AllocationGroupBlue>
              <Label>
                Other Assets Allocation
                <MdHelp
                  size="1.4rem"
                  data-tip={`This is the percentage of your net worth that is in assets not covered in the other categories.
                    These could include assets like real estate or cryptocurrency.
                    Adjust the slider or the value in the 'return' box to set your average projected return NOT adjusted for inflation.`}
                />
              </Label>
              <AllocationGroupRow>
                <ThemedTextarea
                  id="outlined-name"
                  label="Allocation"
                  value={miscAllocation}
                  onChange={(event) => setMiscAllocation(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
                <SliderBlack
                  value={miscRate}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={0}
                  max={10}
                  marks={[
                    {
                      value: 0,
                      label: "0%",
                    },
                    {
                      value: 5,
                      label: "5%",
                    },
                    {
                      value: 10,
                      label: "10%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setMiscRate(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Return"
                  value={miscRate}
                  onChange={(event) => setMiscRate(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRow>
            </AllocationGroupBlue>

            <AllocationGroupGray>
              <Label>Total allocation: {totalAllocation}%</Label>

              {totalAllocation == 100 ? (
                <AllocationMessage>
                  <MdCheck size="1.4rem" />
                  <span>Your allocations total 100%</span>
                </AllocationMessage>
              ) : (
                <AllocationMessage>
                  <MdWarning size="1.4rem" />
                  <span>Please adjust your allocations to total 100%</span>
                </AllocationMessage>
              )}
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Safe Withdrawal Rate
                <MdHelp
                  size="1.2rem"
                  data-tip="Safe withdrawal rate (SWR) is the percentage of your net worth that you withdraw each year in retirement.
                      4% is widely considered as the recommended SWR for retirement planning."
                />
              </Label>
              <AllocationGroupRowTwoCol>
                <SliderBlack
                  value={SWR}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={2}
                  max={6}
                  marks={[
                    {
                      value: 2,
                      label: "2%",
                    },
                    {
                      value: 4,
                      label: "4%",
                    },
                    {
                      value: 6,
                      label: "6%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setSWR(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Rate"
                  value={SWR}
                  onChange={(event) => setSWR(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRowTwoCol>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Inflation rate
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is the average annual rate of inflation that you expect to experience in the future.
                      Historically, the US economy has experienced an annual average inflation rate of 3%.
                      This value is used to normalize the calculator to be in 2020 dollars.`}
                />
              </Label>

              <AllocationGroupRowTwoCol>
                <SliderBlack
                  value={inflationRate}
                  aria-labelledby="discrete-slider-always"
                  step={0.25}
                  min={2}
                  max={6}
                  marks={[
                    {
                      value: 2,
                      label: "2%",
                    },
                    {
                      value: 3,
                      label: "3%",
                    },
                    {
                      value: 6,
                      label: "6%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setInflationRate(value)}
                />

                <ThemedTextarea
                  id="outlined-name"
                  label="Rate"
                  value={inflationRate}
                  onChange={(event) => setInflationRate(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRowTwoCol>
            </AllocationGroupGray>

            {/* <AllocationGroupGray>
                <Label>
                  Side Income Over Age Range
                  <MdHelp size="1.2rem"
                      data-tip={`This is the average annual rate of inflation that you expect to experience in the future.
                      Historically, the US economy has experienced an annual average inflation rate of 3%.
                      This value is used to normalize the calculator to be in 2020 dollars.`}
                  />
                </Label>
                <AllocationGroupRowTwoCol>
                  <SliderBlack
                    value={sideIncomeAgeRange}
                    aria-labelledby="discrete-slider-always"
                    step={1}
                    min={20}
                    max={100}
                    marks={[
                      {
                        value: 20,
                        label: '20'
                      },
                      {
                        value: 50,
                        label: '50'
                      },
                      {
                        value: 100,
                        label: '100'
                      }
                    ]}
                    valueLabelDisplay="auto"
                    onChange={ (event, value) => setSideIncomeAgeRange(value)}
                  />
                  <ThemedTextarea
                    id="outlined-name"
                    label="Monthly"
                    value={sideIncomeMonthly}
                    onChange={event => setSideIncomeMonthly(event.target.value)}
                    variant="filled"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    size="small"
                    fullWidth
                  />
                </AllocationGroupRowTwoCol>
              </AllocationGroupGray> */}

            <AllocationGroupGray>
              <Label>
                Company 401k Match
                <MdHelp
                  size="1.2rem"
                  data-tip={`Do you expect to have a company 401k match going forward in your career?
                      If so, then enter your expected 401k match as a %. Any number above zero will be multiplied by your income and added each year.`}
                />
              </Label>

              <AllocationGroupRowTwoCol>
                <SliderBlack
                  value={companyMatch}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={0}
                  max={10}
                  marks={[
                    {
                      value: 0,
                      label: "0%",
                    },
                    {
                      value: 4,
                      label: "4%",
                    },
                    {
                      value: 10,
                      label: "10%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setCompanyMatch(value)}
                />
                <ThemedTextarea
                  id="outlined-name"
                  label="Rate"
                  value={companyMatch}
                  onChange={(event) => setCompanyMatch(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRowTwoCol>
            </AllocationGroupGray>

            <AllocationGroupGray>
              <Label>
                Income Growth Rate
                <MdHelp
                  size="1.2rem"
                  data-tip={`This is your expected annual income growth rate, NOT adjusted for inflation.`}
                />
              </Label>
              <AllocationGroupRowTwoCol>
                <SliderBlack
                  value={incomeGrowthRate}
                  aria-labelledby="discrete-slider-always"
                  step={0.5}
                  min={0}
                  max={10}
                  marks={[
                    {
                      value: 0,
                      label: "0%",
                    },
                    {
                      value: 4,
                      label: "4%",
                    },
                    {
                      value: 10,
                      label: "10%",
                    },
                  ]}
                  valueLabelDisplay="auto"
                  onChange={(event, value) => setIncomeGrowthRate(value)}
                />
                <ThemedTextarea
                  id="outlined-name"
                  label="Rate"
                  value={incomeGrowthRate}
                  onChange={(event) => setIncomeGrowthRate(event.target.value)}
                  variant="filled"
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  size="small"
                  fullWidth
                />
              </AllocationGroupRowTwoCol>
            </AllocationGroupGray>
          </InputGroup>

          <ResultsPanel>
            <ResultsGroup>
              <span className="label">Your FIRE number: </span>
              <span className="result-value">
                <NumberFormat
                  displayType={"text"}
                  value={fireNumber.toFixed(0)}
                  thousandSeparator
                  prefix="$"
                />
              </span>
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Your FIRE age: </span>
              { fireAge ? <span className="result-value">{fireAge}</span> : <span className="result-value">You won't reach FIRE before your entered retirement age</span>}
            </ResultsGroup>

            <ResultsGroup>
              <span className="label">Years until FIRE: </span>
              
              { fireAge ? <span className="result-value">{fireAge - currentAge}</span> : ""}
            </ResultsGroup>
          </ResultsPanel>
        </form>

        <GraphDesktop>
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={netWorthData} margin={{ top: 10, right: 0, left: 50, bottom: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="age"
                label={{ value: "Age (years)", position: "insideBottom", offset: -10 }}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                }}
              />
              {/* <Tooltip/> */}
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={-20} />
              {/* <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' /> */}
              {/* <Area type='monotone' name="Net worth with continued contributions" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} /> */}
              <Area
                type="monotone"
                name="Net Worth"
                dataKey="actualNetWorth"
                stroke="#3AC732"
                fill="#CFF0CD"
              />
              <Area
                type="monotone"
                name="FIRE number"
                dataKey="fireNumber"
                stroke="#FF1733"
                fillOpacity={0}
              />
            </AreaChart>
          </ResponsiveContainer>
        </GraphDesktop>

        <GraphMobile>
          <ResponsiveContainer width="100%" height={500}>
            <AreaChart data={netWorthData} margin={{ top: 10, right: 0, left: 10, bottom: 30 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="age"
                label={{ value: "Age (years)", position: "insideBottom", offset: -10 }}
              />
              <YAxis
                tickFormatter={(tick) => {
                  return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
                }}
                mirror
              />
              {/* <Tooltip/> */}
              <Tooltip content={<CustomTooltip />} />
              <Legend verticalAlign="top" height={36} />
              {/* <Area type='monotone' name="Coast FIRE number" dataKey='theoreticalNetWorth' stroke='#1274E0' fill='#CCE4FF' /> */}
              {/* <Area type='monotone' name="Net worth with continued contributions" dataKey='continuousGrowthNetWorth' stroke='#b4b4b4' strokeDasharray="10 10" fillOpacity={0} /> */}
              <Area
                type="monotone"
                name="Net Worth"
                dataKey="actualNetWorth"
                stroke="#3AC732"
                fill="#CFF0CD"
              />
              <Area
                type="monotone"
                name="FIRE number"
                dataKey="fireNumber"
                stroke="#FF1733"
                fillOpacity={0}
              />
            </AreaChart>
          </ResponsiveContainer>
        </GraphMobile>

        <NotePanel>
          <MdInfo size="20px" />
          <p>
            Note that all graph values are in current dollars, adjusted for future inflation.
          </p>
        </NotePanel>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>Yearly Finances Table</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Age</span>
                  <span>Years Elapsed</span>
                  <span>Annual Income</span>
                  <span>Annual Savings</span>
                  <span>Net Worth</span>
                </FinancesTableRow>

                {netWorthData.map((dataPoint, index) => {
                  if (!index) {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.age}</span>
                        <span>{dataPoint.year}</span>
                        <span>{dataPoint.annualIncome}</span>
                        <span>{dataPoint.annualSavings}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.actualNetWorth.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  } else {
                    return (
                      <FinancesTableRow>
                        <span>{dataPoint.age}</span>
                        <span>{dataPoint.year}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualIncome.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.annualSavings.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.actualNetWorth.toFixed(0)}
                            thousandSeparator
                            prefix="$"
                          />
                        </span>
                      </FinancesTableRow>
                    );
                  }
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>
      </CalculatorGrid>

      <GumroadAd />

      <RelatedPagesPanel style={{marginTop: '24px'}}>
        <PanelLabel>Related Pages</PanelLabel>
        <RelatedPagesGroup>
          <Link to='/tools/coast-fire-calc'> Coast FIRE Calculator</Link>
          <Link to='/tools/compound-interest-calculator'>Compound Interest Calculator</Link>
          <Link to='/tools/savings-rate-calculator'>Savings Rate Calculator</Link>
        </RelatedPagesGroup>
      </RelatedPagesPanel>

      <ContainerMain>
        <GoogleAdWrapper /> 
      </ContainerMain>

      <ContainerTwoCol>
        <ContainerContent>

        <h2>What is FIRE (Financial Independence / Retire Early)?</h2>
        <p>
          Financial Independence / Retire Early (FIRE) is a personal finance milestone where you
          have accumulated enough assets that the returns on your investments are enough to cover
          your cost of living. Once you have reached FIRE, you can live off the returns from your
          investments indefinitely and you have the freedom to spend your time however you like,
          without the need to work for income.
        </p>
        <p>
          You don't have to wait until conventional retirement age to gain complete freedom over
          your time. Adjust the inputs on this calculator to see how soon you can reach FIRE and how
          much you need to accumulate to reach this milestone.
        </p>

        <h2>Using this calculator</h2>
        <p>
          This interactive calculator makes it easy to calculate and visualize the growth of your
          net worth on your journey to Financial Independence.
        </p>

        <p>
          To use this calculator, simply fill in the inputs at the top of the page and watch the graph automatically update!
        </p>

        <ul>
          <li>Start by entering your <strong>current age</strong>, <strong>current annual take-home pay</strong>, and <strong>current annual spending</strong>.
            Your current annual take-home pay is your post-tax income and does include any contributions that
            you make to retirement accounts like a 401k or HSA.</li>
          <li>
            <strong>Annual spending in retirement</strong> is the amount you plan to spend each year in retirement.
            Note that in many cases, this number will be less your current spending, because
            (1) you will be covered by Medicare and (2) you may have paid off your primary residence, so you no longer have to pay a mortgage.
          </li>
          <li>
            <strong>Current Net Worth</strong> is your total current net worth including stocks, bonds, cash, and retirement accounts. This would not include real estate equity, unless you later plan to sell your real estate in retirement.
            For example, if you have $100,000 invested in the stock market (perhaps through your employer's 401k) and $25,000 saved in your emergency fund, then you should enter $125,000 in this field.
          </li>
          <li>
            Use the sliders to adjust the rates and watch the graph to the right immediately react to your change!
          </li>
          <li>
            <strong>Investment rate of return </strong> is the average return that you expect your investments to grow, not adjusted for inflation.
            This calculator uses 7% as a default Investment rate of return, which is a relatively conservative assumption.
            Historically, the S&P 500 has returned on average 10% annually from its inception in 1926 to 2018.
          </li>
          <li>
            <strong>Inflation rate </strong> is the average annual rate of inflation that you expect to experience in the future.
            Historically, the US economy has experienced an annual average inflation rate of 3%.
          </li>
          <li>
            <strong>Safe Withdrawal Rate </strong> (SWR) is the estimated percentage of your net worth that you expect to withdraw to cover your living expenses in retirement.
            4% is widely considered as the recommended SWR for retirement planning.
            This 4% withdrawal rate was found by <a href="https://www.bogleheads.org/wiki/Trinity_study_update" target="_blank"> the Trinity Study</a> to have a 100% success rate over a 30-year retirement horizon with a 50% / 50% mix of stocks and bonds.
          </li>
        </ul>

        <h2>How do I calculate my FIRE number?</h2>
        <p>
          Your FIRE number is the amount of money you need to have invested such that the returns from your investments
          are enough to cover your ongoing living expenses. This number is based only on your estimated annual spending in retirement
          and your Safe Withdrawal Rate (SWR):
        </p>

        <p>
          <em>(FIRE number) = (annual spending) / SWR</em>
        </p>

        <h2>The math behind the calculator</h2>
        <p>
          This calculator uses your input allocation percentages and rate-of-return for each to calculate a weighted-average rate-of-return
          for your net worth as it moves through the simulation.
        </p>

        <p>
          At its core, this calculator uses the compound interest formula: <em>A = P * ( 1 + n)^t</em>
        </p>

        <p>
          Here A is the final amount, P is the principle (initial amount), n is the annual growth rate, and t is the time in years.
        </p>

        <p>
          Your net worth is calculated recursively (based off the previous year) on a year by year basis, starting at the present day (year 0) with your given inputs.
          For example:
        </p>

        <em>
          Net worth at year 1 = previousYearNetWorth * (1 + rate) + (startingTakeHomePay - startingAnnualSpending) + (startingSalary * company401kmatch)
        </em>

        <p>
          Here <em>rate</em> is your weighted-average rate-of-return minus the inflation rate. In this case, <em>previousYearNetWorth</em> is
          your starting net worth. In subsequent years, <em>startingTakeHomePay</em> and <em>startingSalary</em> are increased using the compound interest formula
          with the income growth rate that you input.
        </p>

        <h2>What about inflation?</h2>
        <p>
          Inflation is an important variable to account for when planning for retirement decades in
          the future. It is almost certain that we will experience inflation in the future, and for
          this reason having your money <u>invested in assets</u> and <u>not</u> all stuffed under
          your mattress is crucially important. Assets like stocks and real estate tend to rise with
          inflation while cash loses value, meaning the best way to preserve your wealth in times of
          high inflation is to be invested in these assets.
        </p>

        <p>
          <strong>Don't worry about inflation, it's built-in to the calculator! </strong>
          This calculator accounts for inflation by subtracting the inflation rate (from the input
          slider) from the investment growth rate of return. This gives an{" "}
          <em>inflation-adjusted </em>
          rate of return which is then used to calculate your FIRE number and draw the graph. With
          this approach, all the numbers in the calculator are adjusted to be in today's dollars. Think
          about it like this - you don't have to worry about cost-of-living increases because it's
          already skimmed off of your expected investment returns.
        </p>
        </ContainerContent>

        <Sidebar />

      </ContainerTwoCol>

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
            shortname={process.env.GATSBY_DISQUS_NAME}
            config={{
              identifier: "fire-calculator",
            }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  );
};

export default FireCalc;
